
import React from 'react';
import { Button } from 'antd';
import { EdgeImg } from './components/EdgeImg'

export const isImg = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?/;
export const getChildrenToRender = (item, i) => {
  let tag = item.name.indexOf('title') === 0 ? 'h1' : 'div';
  tag = item.href ? 'a' : tag;
  let children
  if (typeof item.children === 'string' && item.children.match(isImg)) {
    children = React.createElement('img', { src: item.children, alt: 'img' })
  } else if (typeof item.children === 'string' && item.children.match(/\.(png|svg|jpg)$/)) {
    children = <EdgeImg name={item.children} noBase64></EdgeImg>
  } else {
    children = item.children
  }

  if (item.name.indexOf('button') === 0 && typeof item.children === 'object') {
    children = React.createElement(Button, {
      ...item.children
    });
  }
  return React.createElement(tag, { key: i.toString(), ...item }, children);
};
