import React from 'react';
import TweenOne from 'rc-tween-one';
import { Menu } from 'antd';
import { getChildrenToRender } from '../utils';
import ClientRender from './ClientRender'
import { EdgeImg } from './EdgeImg'
import { Link } from "gatsby"

const { Item, SubMenu } = Menu;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneOpen: undefined,
    };
  }

  phoneClick = () => {
    const phoneOpen = !this.state.phoneOpen;
    this.setState({
      phoneOpen,
    });
  };

  render() {
    const { dataSource, isMobile, ...props } = this.props;
    const { phoneOpen } = this.state;
    const navData = dataSource.Menu.children;
    const navChildren = navData.map((item) => {
      const { children: a, subItem, ...itemProps } = item;
      if (subItem) {
        return (
          <SubMenu
            key={item.name}
            {...itemProps}
            title={
              <div
                {...a}
                className={`header0-item-block ${a.className}`.trim()}
              >
                {a.children.map(getChildrenToRender)}
              </div>
            }
            popupClassName="header0-item-child"
          >
            {subItem.map(($item, ii) => {
              const { children: childItem } = $item;
              const child = childItem.href ? (
                <a {...childItem}>
                  {childItem.children.map(getChildrenToRender)}
                </a>
              ) : (
                <div {...childItem}>
                  {childItem.children.map(getChildrenToRender)}
                </div>
              );
              return (
                <Item key={$item.name || ii.toString()} {...$item}>
                  {child}
                </Item>
              );
            })}
          </SubMenu>
        );
      }
      return (
        <Item key={item.name} {...itemProps}>
          {
            a.linkTo ? (
              <Link to={a.linkTo} activeClassName="active" className={`header0-item-block ${a.className}`.trim()}>
                {a.children.map(getChildrenToRender)}
              </Link>
            ) : (
              <a {...a} className={`header0-item-block ${a.className}`.trim()}>
                {a.children.map(getChildrenToRender)}
              </a>
            )
          }
        </Item>
      );
    });
    const bgColor = isMobile ? 'red' : 'green'
    const moment = phoneOpen === undefined ? 300 : null;
    return (
      <div
        {...dataSource.wrapper}
        {...props}
      >
        {/* <div style={{fontSize: '40px', color: 'yellow'}}>{isMobile ? 'isMobile' : 'NOT MOBILE'}</div> */}
        {/* <div style={{fontSize: '40px', color: 'yellow'}}>{bgColor}</div> */}
        <div
          {...dataSource.page}
          className={`${dataSource.page.className}${phoneOpen ? ' open' : ''}`}
        >
          <TweenOne
            animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
            {...dataSource.logo}
          >
            <a href="/"><EdgeImg width="100%" noBase64 name={dataSource.logo.children} alt="img" /></a>
          </TweenOne>
          <ClientRender>
          {isMobile && (
            <div
              {...dataSource.mobileMenu}
              onClick={() => {
                this.phoneClick();
              }}
            >
              <em />
              <em />
              <em />
            </div>
          )}
        {
          !isMobile && (<TweenOne
            {...dataSource.Menu}
            animation={
              isMobile
                ? {
                    height: 0,
                    duration: 300,
                    onComplete: (e) => {
                      if (this.state.phoneOpen) {
                        e.target.style.height = 'auto';
                      }
                    },
                    ease: 'easeInOutQuad',
                  }
                : null
            }
            moment={moment}
            reverse={!!phoneOpen}
          >
            <Menu
              mode={isMobile ? 'inline' : 'horizontal'}
              defaultSelectedKeys={['sub0']}
              theme="dark"
            >
              {navChildren}
            </Menu>
          </TweenOne>)
        }
        </ClientRender>
        </div>
        <ClientRender>
        {
          isMobile && (
            <TweenOne
                {...dataSource.Menu}
                animation={
                  isMobile
                    ? {
                        // height: 0,
                        duration: 300,
                        onComplete: (e) => {
                          if (this.state.phoneOpen) {
                            e.target.style.height = 'auto';
                          }
                        },
                        ease: 'easeInOutQuad',
                      }
                    : null
                }
                moment={moment}
                reverse={!!phoneOpen}
                className={`${dataSource.Menu.className} ${phoneOpen ? ' open' : ''}`}
              >
                <Menu
                  mode={isMobile ? 'inline' : 'horizontal'}
                  defaultSelectedKeys={['sub0']}
                  theme="dark"
                >
                  {navChildren}
                </Menu>
            </TweenOne>
          )
        }
      </ClientRender>
      </div>
    );
  }
}

export default Header;
