import { useStaticQuery, graphql } from "gatsby"

export const useAllImageEdgesNoBase64 = () => {
  const { allImages } = useStaticQuery(
    graphql`
    query {
        allImages: allFile {
            edges {
              node {
                name
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1920) {
                    originalName
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
                extension
                publicURL
              }
            }
        }
    }
    `
  )
  return allImages.edges
}
