import React, { Fragment } from 'react';

class ClientRender extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isClient: false,
    }
  }

  componentDidMount() {
    this.setState({ isClient: true })
  }

  render() {
    const { children } = this.props
    const { isClient } = this.state

    if (isClient) {
      return <Fragment>
        {children}
      </Fragment>
    } else return <div></div>
  }
}

export default ClientRender
