import React, { Fragment } from 'react';
import { Button } from 'antd';
import QueueAnim from 'rc-queue-anim';
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';
import { EdgeBackgroundImg } from './EdgeImg'

class Banner extends React.PureComponent {
  render() {
    const { ...currentProps } = this.props;
    const { dataSource } = currentProps;
    delete currentProps.dataSource;
    const children = dataSource.textWrapper.children.map((item) => {
      const { name, texty, ...$item } = item;
      if (name.match('button')) {
        return (
          <Button type="primary" key={name} {...$item}>
            {item.children}
          </Button>
        );
      }

      return (
        <div key={name} {...$item}>
          {texty ?
            typeof item.children === 'string' ? (<Texty type="mask-bottom" delay={500} duration={1000}>{item.children}</Texty>)
            : (<Fragment>{item.children.map(child => <Texty type="mask-bottom" delay={500} duration={1000}>{child}</Texty>)}</Fragment>)
           : (
            item.children
          )}
        </div>
      );
    });
    return (
      <EdgeBackgroundImg name={dataSource.bgPath} {...currentProps} {...dataSource.wrapper}>
        {/* <div {...currentProps} {...dataSource.wrapper}> */}
        <div style={{width: '100%', height: '100%', backgroundColor: 'rgb(99,99,99)', opacity: '0.4'}}></div>
        <QueueAnim
          key="QueueAnim"
          type={['bottom', 'top']}
          delay={500}
          duration={100}
          {...dataSource.textWrapper}
        >
          {children}
        </QueueAnim>
        {/* </div> */}
      </EdgeBackgroundImg>

    );
  }
}
export default Banner;
