import React from 'react';
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'
import { useAllImageEdges } from "../hooks/useAllImageEdges"
import { useAllImageEdgesNoBase64 } from "../hooks/useAllImageEdgesNoBase64"

const getFluidByName = (edges, name) => {
  const edge = edges.find((edge) => edge.node.childImageSharp && edge.node.childImageSharp.fluid.originalName === name)

  return edge ? edge.node.childImageSharp.fluid : null
}

const getPublicUrlByName = (edges, name) => {
  const edge = edges.find((edge) => `${edge.node.name}.${edge.node.extension}` === name)

  return edge ? edge.node.publicURL : null
}

const getImgElement = (edges, name, props) => {
  const ext = name.split('.')[1]
  const { aspectRatio } = props
  const aspectRatioSetting = aspectRatio ? { aspectRatio } : {}
  if (ext === 'svg') {
    return <img alt={name} src={getPublicUrlByName(edges, name)} {...props}></img>
  } else {
    return <Img loading={'eager'} fluid={{ ...getFluidByName(edges, name), ...aspectRatioSetting }} imgStyle={{
      objectFit: "cover",
      objectPosition: "50% 50%",
    }} alt={name} {...props}></Img>
  }
}

const getBackgroundImgElement = (edges, name, props, children) => {
  const ext = name.split('.')[1]
  const { bgSize, position, style } = props
  const backgroundPosition = position ? { backgroundPosition: position } : {}
  const backgroundSize = bgSize ? { backgroundSize: bgSize } : {}

  if (ext === 'svg') {
    return <div style={{backgroundPosition: 'center', backgroundSize: 'contain', ...backgroundPosition, ...backgroundSize, backgroundImage: `url(${getPublicUrlByName(edges, name)})`}} {...props}>{children}</div>
  } else {
    return <BackgroundImage fadeIn={true} fluid={getFluidByName(edges, name)} style={{ backgroundPosition: 'center', backgroundSize: 'cover', ...style, ...backgroundPosition, ...backgroundSize }} {...props}>{children}</BackgroundImage>
    // return <div style={{backgroundPosition: 'center', backgroundSize: 'cover', ...backgroundPosition, ...backgroundSize, backgroundImage: `url(${getPublicUrlByName(edges, name)})`}} {...props}>{children}</div>
  }
}

export const ImageContext = React.createContext([]);

export const EdgeImg = (props) => {
  const { name, noBase64 } = props
  const edges = useAllImageEdges()
  const edgesNoBase64 = useAllImageEdgesNoBase64()
  const MyImg = getImgElement(noBase64 ? edgesNoBase64 : edges, name, props)
  return MyImg
}

export const EdgeBackgroundImg = (props) => {
  const { name, children } = props
  const edges = useAllImageEdges()
  const MyImg = getBackgroundImgElement(edges, name, props, children)
  return MyImg
}
