import React, { useState } from 'react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { EdgeImg, EdgeBackgroundImg } from './EdgeImg'

const FixedButton = () => {
    const [isHidden, setIsHidden] = useState(true)

    useScrollPosition(
        ({ prevPos, currPos }) => {
            // console.log(currPos)
            setIsHidden(currPos.y > (-1 * window.innerHeight) * 0.7)
        },
        [isHidden]
    )

    return <div className={`fixed-button ${isHidden ? 'hidden' : ''}`}>
        <a href="#contactForm">
            <EdgeImg name={'contact_fixed_button.png'}></EdgeImg>
        </a>
    </div>
}

export default FixedButton
