import React from 'react';
import ClientRender from './ClientRender'
import { EdgeImg, EdgeBackgroundImg } from './EdgeImg'
import FixedButton from './FixedButton';

class Contact1 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleSubmit = event => {
    alert(`您的資料已送出，客服人員將與您聯繫！`)
  }

  render() {
    const { ...props } = this.props;
    const { dataSource, isMobile } = props;
    delete props.dataSource;
    delete props.isMobile;

    const form = (            <div className="ant-col ant-col-xs-24 ant-col-md-8">
    <div className="contact-form" id="contactForm">
      <div className="title">若有任何疑問、業務聯絡<br/>歡迎利用下列表單與我們聯繫</div>
      <iframe name="hidden_iframe" title="hidden_iframe" style={{display: 'none'}}></iframe>
      <form action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSeg1oHE-CDdJggG8I-IFaTVqgLnJ104aln0PthP1rT7IzI9Pg/formResponse"
          target="hidden_iframe"
          method="POST"
          onSubmit={this.handleSubmit}>
        <div className="form-input-wrapper">
          <div className="form-input">
              <input className="" type="text" name="entry.1881393286" placeholder="姓名 NAME" required />
          </div>
          <div className="form-input">
              <input className="" type="number" name="entry.237284060" placeholder="電話 PHONE" required />
          </div>
          <div className="form-input">
              <input className="" type="text" name="entry.1451598054" placeholder="電子郵件 EMAIL" />
          </div>
          <div className="form-input">
              <textarea className="" type="text" name="entry.1545948821" rows="3" placeholder="詢問內容" />
          </div>
          <button type="submit" className="form-button">
            送出表單
          </button>
        </div>
      </form>
    </div>
  </div>
  )
    const contact = (<div className="ant-col ant-col-xs-24 ant-col-md-8">
    <div className="contact-info-wrapper">
      <EdgeImg className="logo" name={'logo.png'}></EdgeImg>
      <div className="contact-info">
        <EdgeImg className="contact-icon" name={'phone_icon.png'}></EdgeImg>
        <div className="phone">02 2712-8298</div>
      </div>
      <div className="contact-info">
        <EdgeImg className="contact-icon" name={'email_icon.png'}></EdgeImg>
        <EdgeImg className="email"name={isMobile ? 'email_address_black.png' : 'email_address.png'}></EdgeImg>
      </div>
      <div className="contact-info">
        <EdgeImg className="contact-icon" name={'location_icon.png'}></EdgeImg>
        <div className="address">台北市松山區<br/>民權東路三段142號14樓1403室</div>
      </div>
    </div>
  </div>)
  const fb = (<div className="ant-col ant-col-xs-24 ant-col-md-8">
  <div style={{ width: '280px', margin: 'auto' }}>
    <iframe
      src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FBee-House-100123864861754&tabs=timeline&width=280&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=false"
      width="280" height="500" style={{ border: 'none', overflow: 'hidden', margin: 'auto'}}
      scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media">
    </iframe>
  </div>
</div>)
    return (
      <ClientRender>
        { isMobile ?
          (
            <div className="contact1-container">
              <div className="ant-row contact1-wrapper">
                {fb}
                {form}
                <div className="ant-col ant-col-xs-24">
                  <EdgeBackgroundImg name={'map_mobile.png'} style={{height: '60vw', width: '100%'}} />
                </div>
                {contact}
              </div>
              <FixedButton></FixedButton>
              {/* <div className="fixed-button">
                <a href="#contactForm">
                  <EdgeImg name={'contact_fixed_button.png'}></EdgeImg>
                </a>
              </div> */}
            </div>
          ) :
          (
            <div className="contact1-container">
              <div className="ant-row contact1-wrapper">
                {form}
                {contact}
                {fb}
              </div>
              <EdgeBackgroundImg name={'map.png'} style={{height: '20vw', width: '100%'}} />
            </div>
          )
      }
      </ClientRender>
    );
  }
}

export default Contact1;
