import React from 'react';
export const Nav00DataSource = {
  wrapper: { className: 'header0' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    children: 'logo.png',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          href: '#',
          linkTo: '/solution',
          children: [{ children: 'SOLUTION', name: 'text' }],
        },
        // subItem: [
        //   {
        //     name: 'sub0',
        //     className: 'item-sub',
        //     children: {
        //       className: 'item-sub-item',
        //       children: [
        //         {
        //           name: 'image0',
        //           className: 'item-image',
        //           children:
        //             'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
        //         },
        //         {
        //           name: 'title',
        //           className: 'item-title',
        //           children: 'Ant Design',
        //         },
        //         {
        //           name: 'content',
        //           className: 'item-content',
        //           children: '企业级 UI 设计体系',
        //         },
        //       ],
        //     },
        //   },
        //   {
        //     name: 'sub1',
        //     className: 'item-sub',
        //     children: {
        //       className: 'item-sub-item',
        //       children: [
        //         {
        //           name: 'image0',
        //           className: 'item-image',
        //           children:
        //             'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
        //         },
        //         {
        //           name: 'title',
        //           className: 'item-title',
        //           children: 'Ant Design',
        //         },
        //         {
        //           name: 'content',
        //           className: 'item-content',
        //           children: '企业级 UI 设计体系',
        //         },
        //       ],
        //     },
        //   },
        // ],
      },
      {
        name: 'item1',
        className: 'header0-item',
        children: {
          href: '#',
          linkTo: '/works',
          children: [{ children: 'WORKS', name: 'text' }],
        },
      },
      {
        name: 'item2',
        className: 'header0-item',
        children: {
          href: '#',
          linkTo: '/about',
          children: [{ children: 'ABOUT', name: 'text' }],
        },
      },
      {
        name: 'item3',
        className: 'header0-item',
        children: {
          href: '#contactForm',
          children: [{ children: 'CONTACT', name: 'text' }],
        },
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Banner20DataSource = {
  wrapper: { className: 'banner2' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        page: { className: 'home-page banner2-page' },
        textWrapper: { className: 'banner2-text-wrapper' },
        bg: { className: 'bg bg0' },
        title: { className: 'banner2-title', children: 'Ant Motion' },
        content: {
          className: 'banner2-content',
          children: '一个高效的页面动画解决方案',
        },
        button: { className: 'banner2-button', children: 'Learn More' },
      },
    ],
  },
};
export const Banner00DataSource = {
  wrapper: { className: 'banner0' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title',
    children: 'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
  },
  content: {
    className: 'banner0-content',
    children: '一个高效的页面动画解决方案',
  },
  button: { className: 'banner0-button', children: 'Learn More' },
};
export const Banner30DataSource = {
  wrapper: { className: 'banner3' },
  bgPath: 'works_bg.jpg',
  textWrapper: {
    className: 'banner3-text-wrapper k6ly51cs5qm-editor_css',
    children: [
      // {
      //   name: 'nameEn',
      //   className: 'banner3-name-en',
      //   children: 'Seeking Experience & Engineering Conference',
      // },
      {
        name: 'slogan',
        className: 'banner3-slogan',
        // children: '與客戶共同成長 發揮最大效益',
        children: ['與客戶共同成長', '發揮最大效益'],
        texty: true,
      },
      // {
      //   name: 'name',
      //   className: 'banner3-name',
      //   children: '探索极致用户体验与最佳工程实践探索',
      // },
      // { name: 'button', className: 'banner3-button', children: '立即报名' },
      // {
      //   name: 'time',
      //   className: 'banner3-time',
      //   children: '2018.01.06 / 中国·杭州',
      // },
    ],
  },
};
export const Banner31DataSource = {
  wrapper: { className: 'banner3' },
  bgPath: 'solution_bg.jpg',
  textWrapper: {
    className: 'banner3-text-wrapper k6ly51cs5qm-editor_css',
    children: [
      // {
      //   name: 'nameEn',
      //   className: 'banner3-name-en',
      //   children: 'Seeking Experience & Engineering Conference',
      // },
      {
        name: 'slogan',
        className: 'banner3-slogan align-left',
        // children: '與客戶共同成長 發揮最大效益',
        children: ['不同階段', '客製數位整合策略'],
        texty: true,
      },
      // {
      //   name: 'name',
      //   className: 'banner3-name',
      //   children: '探索极致用户体验与最佳工程实践探索',
      // },
      // { name: 'button', className: 'banner3-button', children: '立即报名' },
      // {
      //   name: 'time',
      //   className: 'banner3-time',
      //   children: '2018.01.06 / 中国·杭州',
      // },
    ],
  },
};
export const Banner310DataSource = {
  wrapper: { className: 'banner3' },
  bgPath: 'solution_bg.jpg',
  textWrapper: {
    className: 'banner3-text-wrapper k6ly51cs5qm-editor_css',
    children: [
      // {
      //   name: 'nameEn',
      //   className: 'banner3-name-en',
      //   children: 'Seeking Experience & Engineering Conference',
      // },
      {
        name: 'slogan',
        className: 'banner3-slogan align-left',
        // children: '與客戶共同成長 發揮最大效益',
        children: ['不同階段', '數位整合策略'],
        texty: true,
      },
      // {
      //   name: 'name',
      //   className: 'banner3-name',
      //   children: '探索极致用户体验与最佳工程实践探索',
      // },
      // { name: 'button', className: 'banner3-button', children: '立即报名' },
      // {
      //   name: 'time',
      //   className: 'banner3-time',
      //   children: '2018.01.06 / 中国·杭州',
      // },
    ],
  },
};
export const Banner32DataSource = {
  wrapper: { className: 'banner3' },
  bgPath: 'about_bg.jpg',
  textWrapper: {
    className: 'banner3-text-wrapper k6ly51cs5qm-editor_css',
    children: [
      {
        name: 'slogan',
        className: 'banner3-slogan align-right',
        // children: '與客戶共同成長 發揮最大效益',
        children: ['專注房地產', '全方位數位整合行銷'],
        texty: true,
      },
    ],
  },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper',
    playScale: 0.3,
  },
  page: { className: 'home-page content13' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      { name: 'title', children: '精準投放 精省成本', className: 'title-h1 good-word-break' },
      {
        name: 'content',
        children:
          '以獨家分眾投放為基礎，化繁為簡將所有能接觸到潛力買家的平台介接整合',
        className: 'title-content good-word-break',
      },
      {
        name: 'content2',
        children: '全方位收集＆分析CI數據，隨時且即時判斷成效的差異，幫助優化成效',
        className: 'title-content good-word-break',
      },
    ],
  },
};
export const Content140DataSource = {
  OverPack: {
    className: 'home-page-wrapper content14-wrapper',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      // {
      //   name: 'image',
      //   children:
      //     'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
      //   className: 'title-image',
      // },
      { name: 'title', children: '睛準客層 降低成本 提高成交率', className: 'title-h1 good-word-break' },
      {
        name: 'content',
        children:
          '針對房產行銷四階段提供完整策略，解構潛力買家資料',
        className: 'title-content good-word-break',
      },
      {
        name: 'content',
        children:
          '不僅鎖定高含金量客戶，並減低投放成本，提升成交率',
        className: 'title-content good-word-break',
      },
      // {
      //   name: 'content2',
      //   children: '全方位收集＆分析CI數據，隨時且即時判斷成效的差異，幫助優化成效',
      //   className: 'title-content',
      // },
    ],
  },
  childWrapper: {
    className: 'home-page block-wrapper',
    children: [
      {
        name: 'image',
        children: 'solution_progress.png',
        className: 'image'
      },
      {
        name: 'image',
        children: 'solution_matrix.png',
        className: 'image'
      }
    ]
  }
};
export const Content150DataSource = {
  OverPack: {
    className: 'home-page-wrapper content15-wrapper',
    playScale: 0.3,
  },
  page: { className: 'home-page content15' },
  titleWrapper: {
    className: 'title-wrapper align-left',
    children: [
      // {
      //   name: 'image',
      //   children:
      //     'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
      //   className: 'title-image',
      // },
      { name: 'title', children: '緊扣購屋趨勢 隨時創新行銷策略', className: 'title-h1 good-word-break' },
      // {
      //   name: 'content',
      //   children:
      //     '「我們專注於房地產專業領域，提供全方位數位整合行銷，精準投放目標客戶、精省成本、創造最大值行銷效益！」',
      //   className: 'title-content',
      // },
      // {
      //   name: 'content2',
      //   children: '全方位收集＆分析CI數據，隨時且即時判斷成效的差異，幫助優化成效',
      //   className: 'title-content',
      // },
    ],
  },
  childWrapper: {
    className: 'block-wrapper ant-col-sm-24',
    children: [
      {
        name: 'content',
        className: 'block-content',
        children: [
          <strong>網路行銷近幾年來</strong>,
          '已改寫紙本媒體的歷史，現在及未來的房地產廣告行銷方式也將走向雲端及無紙化的網路行銷平台，並已成為緊扣購屋者動向的趨勢主流！',
        ]
      },
      {
        name: 'content',
        className: 'block-content',
        children: [
          <strong>我們是一群</strong>,
          '擁有30年以上地產企劃經驗及網路實戰的團隊！我們尊崇蜜蜂勤奮的DNA及團隊精神，我們以蜂自詡，以BEE＋為名，以大數據為根，以人為本， BEE＋必加廣告，必定為您加分、加值！',
        ]
      },
    ]
  },
  mobileChildWrapper: {
    className: 'block-wrapper ant-col-24',
    children: [
      {
        name: 'content',
        className: 'block-content',
        children: ['我們專注於房地產專業領域，']
      },
      {
        name: 'content',
        className: 'block-content',
        children: ['提供全方位數位整合行銷，']
      },
      {
        name: 'content',
        className: 'block-content',
        children: ['精準投放目標客戶、精省成本、']
      },
      {
        name: 'content',
        className: 'block-content',
        children: ['創造最大值行銷效益！']
      },
    ]
  }
};

export const Content1510DataSource = {
  OverPack: {
    className: 'home-page-wrapper content151-wrapper',
    playScale: 0.3,
  },
  page: { className: 'home-page content151' },
  titleWrapper: {
    className: 'title-wrapper align-left',
    children: [
      // {
      //   name: 'image',
      //   children:
      //     'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
      //   className: 'title-image',
      // },
      { name: 'title', children: <span>獨家優勢<br />任務必達<br />效益加值</span>, className: 'title-h1 good-word-break' },
      // {
      //   name: 'content',
      //   children:
      //     '「我們專注於房地產專業領域，提供全方位數位整合行銷，精準投放目標客戶、精省成本、創造最大值行銷效益！」',
      //   className: 'title-content',
      // },
      // {
      //   name: 'content2',
      //   children: '全方位收集＆分析CI數據，隨時且即時判斷成效的差異，幫助優化成效',
      //   className: 'title-content',
      // },
    ],
  },
  childWrapper: {
    className: 'block-wrapper ant-col-sm-24',
    children: [
      {
        name: 'content',
        className: 'block-content',
        children: [
          <strong>網路行銷近幾年來</strong>,
          '已改寫紙本媒體的歷史，現在及未來的房地產廣告行銷方式也將走向雲端及無紙化的網路行銷平台，並已成為緊扣購屋者動向的趨勢主流！',
        ]
      },
      {
        name: 'content',
        className: 'block-content',
        children: [
          <strong>我們是一群</strong>,
          '擁有30年以上地產企劃經驗及網路實戰的團隊！我們尊崇蜜蜂勤奮的DNA及團隊精神，我們以蜂自詡，以BEE＋為名，以大數據為根，以人為本， BEE＋必加廣告，必定為您加分、加值！',
        ]
      },
    ]
  }
};

export const Content1520DataSource = {
  OverPack: {
    className: 'home-page-wrapper content152-wrapper',
    playScale: 0.3,
  },
  page: { className: 'home-page content152' },
  titleWrapper: {
    className: 'title-wrapper align-left',
    children: [
      // {
      //   name: 'image',
      //   children:
      //     'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
      //   className: 'title-image',
      // },
      { name: 'title', children: '緊扣購屋趨勢 隨時創新行銷策略', className: 'title-h1 good-word-break' },
      // {
      //   name: 'content',
      //   children:
      //     '「我們專注於房地產專業領域，提供全方位數位整合行銷，精準投放目標客戶、精省成本、創造最大值行銷效益！」',
      //   className: 'title-content',
      // },
      // {
      //   name: 'content2',
      //   children: '全方位收集＆分析CI數據，隨時且即時判斷成效的差異，幫助優化成效',
      //   className: 'title-content',
      // },
    ],
  },
  childWrapper: {
    className: 'block-wrapper ant-col-sm-12',
    children: [
      {
        name: 'content',
        className: 'block-content',
        children: [
          <strong>網路行銷近幾年來</strong>,
          '已改寫紙本媒體的歷史，現在及未來的房地產廣告行銷方式也將走向雲端及無紙化的網路行銷平台，並已成為緊扣購屋者動向的趨勢主流！',
        ]
      },
      {
        name: 'content',
        className: 'block-content',
        children: [
          <strong>我們是一群</strong>,
          '擁有30年以上地產企劃經驗及網路實戰的團隊！我們尊崇蜜蜂勤奮的DNA及團隊精神，我們以蜂自詡，以BEE＋為名，以大數據為根，以人為本， BEE＋必加廣告，必定為您加分、加值！',
        ]
      },
]
  },
  mobileChildWrapper: {
    className: 'block-wrapper ant-col-24',
    children: [
      {
        name: 'content',
        className: 'block-content',
        children: ['我們專注於房地產專業領域，']
      },
      {
        name: 'content',
        className: 'block-content',
        children: ['提供全方位數位整合行銷，']
      },
      {
        name: 'content',
        className: 'block-content',
        children: ['精準投放目標客戶、精省成本、']
      },
      {
        name: 'content',
        className: 'block-content',
        children: ['創造最大值行銷效益！']
      },
    ]
  }
};

export const Content160DataSource = {
  OverPack: {
    className: 'home-page-wrapper content16-wrapper',
    playScale: 0.3,
  },
  page: {
    className: 'home-page content-16',
  },
  titleWrapper: {
    className: 'title-wrapper align-left  ant-col-md-12',
    children: [
      // {
      //   name: 'image',
      //   children:
      //     'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
      //   className: 'title-image',
      // },
      { name: 'title', children: '精準投放 精省成本', className: 'title-h1 good-word-break' },
      {
        name: 'content',
        children:
          '以獨家分眾投放為基礎，化繁為簡',
        className: 'title-content good-word-break',
      },
      {
        name: 'content',
        children:
          '將所有潛力買家的平台整合、收集',
        className: 'title-content good-word-break',
      },
      {
        name: 'content',
        children: '並分析數據，即時判斷成效及優化',
        className: 'title-content good-word-break',
      },
    ],
  },

  // childWrapper: {
  //   className: 'block-wrapper',
  //   children: [
  //     {
  //       name: 'content',
  //       className: 'block-content',
  //       children: '網路行銷近幾年來已改寫紙本媒體的歷史，現在及未來的房地產廣告行銷方式也將從報紙、雜誌、派報等傳統模式，走向雲端，無紙化的網路行銷平台，已蔚為房地產行銷主流，如果要讓個案銷售資訊快速、精準、有效地傳達給目標客戶，建商及代銷公司走向以大數據為根本的網路行銷，已成為緊扣購屋者動向的趨勢主流！',
  //     },
  //     {
  //       name: 'content',
  //       className: 'block-content',
  //       children: '我們是一群擁有30年以上房地產企劃經驗及豐富網路行銷實戰績效的團隊，我們尊崇蜜蜂的勤奮DNA、以自然界大數據精準偵測花開期及合作無間的團隊精神，所以，我們以蜜蜂自詡，以BEE＋為名，以大數據為根，以人為本，獨家建構以房地產為主軸的網路行銷專業團隊，BEE＋必加廣告行銷股份有限公司，必定為您加分、加值！',
  //     },
  //   ]
  // }
};

export const Content1610DataSource = {
  OverPack: {
    className: 'home-page-wrapper content161-wrapper',
    playScale: 0.3,
  },
  page: {
    className: 'home-page content-161',
  },
  titleWrapper: {
    className: 'title-wrapper align-left',
    children: [
      // {
      //   name: 'image',
      //   children:
      //     'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
      //   className: 'title-image',
      // },
      { name: 'title', children: '精準投放 精省成本', className: 'title-h1 good-word-break' },
      {
        name: 'content',
        children:
          '以獨家分眾投放為基礎，化繁為簡',
        className: 'title-content good-word-break',
      },
      {
        name: 'content',
        children:
          '將所有潛力買家的平台整合、收集',
        className: 'title-content good-word-break',
      },
      {
        name: 'content',
        children: '並分析數據，即時判斷成效及優化',
        className: 'title-content good-word-break',
      },
      {
        name: 'content',
        children: '',
        className: 'title-content good-word-break',
      },
    ],
  },
  // childWrapper: {
  //   className: 'block-wrapper',
  //   children: [
  //     {
  //       name: 'content',
  //       className: 'block-content',
  //       children: '網路行銷近幾年來已改寫紙本媒體的歷史，現在及未來的房地產廣告行銷方式也將從報紙、雜誌、派報等傳統模式，走向雲端，無紙化的網路行銷平台，已蔚為房地產行銷主流，如果要讓個案銷售資訊快速、精準、有效地傳達給目標客戶，建商及代銷公司走向以大數據為根本的網路行銷，已成為緊扣購屋者動向的趨勢主流！',
  //     },
  //     {
  //       name: 'content',
  //       className: 'block-content',
  //       children: '我們是一群擁有30年以上房地產企劃經驗及豐富網路行銷實戰績效的團隊，我們尊崇蜜蜂的勤奮DNA、以自然界大數據精準偵測花開期及合作無間的團隊精神，所以，我們以蜜蜂自詡，以BEE＋為名，以大數據為根，以人為本，獨家建構以房地產為主軸的網路行銷專業團隊，BEE＋必加廣告行銷股份有限公司，必定為您加分、加值！',
  //     },
  //   ]
  // }
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper good-word-break',
    children: [{ name: 'title', children: '五大獨家 領先業界' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        // md: 6,
        sm: 12,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                // 'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
                'content0-block0.png',
            },
            {
              name: 'title',
              className: 'content0-block-title good-word-break',
              children: '30 年房地產經驗',
            },
            { name: 'content', className: 'content0-block-subtitle', children: '熟悉房地產語言與生態' },
            { name: 'content', className: 'content0-block-subtitle', children: '提供專業策略及新建議' },
          ],
        },
      },
      {
        name: 'block4',
        className: 'content0-block',
        // md: 6,
        sm: 12,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                // 'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
                'content0-block4.png',
            },
            {
              name: 'title',
              className: 'content0-block-title good-word-break',
              children: '房地產平面企劃',
            },
            { name: 'content', className: 'content0-block-subtitle', children: '全案整合企劃' },
            { name: 'content', className: 'content0-block-subtitle', children: '提供優質文案撰寫與視覺設計' },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        // md: 6,
        sm: 12,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                // 'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
                'content0-block1.png',
            },
            {
              name: 'title',
              className: 'content0-block-title good-word-break',
              children: '採獨家分眾資料',
            },
            {
              name: 'content', className: 'content0-block-subtitle good-word-break',
              children: '累積並過濾資料庫',
            },
            {
              name: 'content', className: 'content0-block-subtitle',
              children: '隨時更新並即時掌握購屋需求',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        // md: 6,
        sm: 12,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                // 'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
                'content0-block2.png',
            },
            {
              name: 'title',
              className: 'content0-block-title good-word-break',
              children: '電話銷售＆客服',
            },
            {
              name: 'content', className: 'content0-block-subtitle',
              children: 'LINE、網站、FB、電銷…',
            },
            {
              name: 'content', className: 'content0-block-subtitle',
              children: '創造與客戶的私訊管道',
            },
          ],
        },
      },
      {
        name: 'block3',
        className: 'content0-block',
        // md: 6,
        sm: 12,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                // 'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
                'content0-block3.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: '視覺圖像化報表',
            },
            {
              name: 'content', className: 'content0-block-subtitle',
              children: '量身打造，精準且即時',
            },
            {
              name: 'content', className: 'content0-block-subtitle',
              children: '調整有助行銷的新策略',
            },
          ],
        },
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '超過 100 個建案 共創成功', className: 'title-h1 good-word-break' },
      {
        name: 'content',
        className: 'title-content good-word-break',
        children: '擁有完整的技術及行銷顧問團隊，提供客戶全通路行銷解決方案，達成行銷與科技的完美結合',
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 8,
    children: [
      {
          name: 'block0',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '小春日和.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block1',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '中山Q1.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block2',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '世中心.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block3',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '市府采舍.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block4',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '弘輝大苑.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block5',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '田園調布.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block6',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '全陽大璽.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block7',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '吉美雲品.png'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block8',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '竹城表参道.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block9',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '亞昕昕樂章.png'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block10',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '亞洲企業中心.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block11',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '和暘信邑.PNG'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block12',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '昕世代.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block13',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '昕動台北.png'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block14',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '東騰元町.png'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block15',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '松華商仲.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block16',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '長虹天馥.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block17',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '阿曼學學.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block18',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '阿曼學學.png'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block19',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '星光道.PNG'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block20',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '家家富富.png'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block21',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '站前A+.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block22',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '淞露長安.png'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block23',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '都美艷.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block24',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '富御捷境.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block25',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '富都新紳.png'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block26',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '富喆美樹.png'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block27',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '敦豐苑.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block28',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '景程禾雅.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block29',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '森美.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block30',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '翔譽愛力.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block31',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '鄉林山海滙.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block32',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '新捷市.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block33',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '福璽景安.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block34',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '遠雄之星6.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block35',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '遠雄安南町.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block36',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '遠雄青青 LOGO.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block37',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '遠雄錸儷.png'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block38',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '遠雄H153.png'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block39',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '璞樂.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block40',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '醍湖.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block41',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '鴻築PK ONE.png'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block42',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '藍田御.png'
            },
            content: { children: 'Ant Design' },
          },
      },
      {
          name: 'block43',
          className: 'block',
          children: {
            wrapper: { className: 'content5-block-content' },
            img: {
              children: '權世界.jpg'
            },
            content: { children: 'Ant Design' },
          },
      },

    ],
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '蚂蚁金融云提供专业的服务',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: '基于阿里云强大的基础资源',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '企业资源管理' },
          content: {
            className: 'content3-content',
            children:
              '云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。',
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '云安全' },
          content: {
            className: 'content3-content',
            children:
              '按金融企业安全要求打造的完整云上安全体系，全方位保障金融应用及数据安全。',
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '云监控' },
          content: {
            className: 'content3-content',
            children:
              '分布式云环境集中监控，统一资源及应用状态视图，智能分析及故障定位。',
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '移动' },
          content: {
            className: 'content3-content',
            children:
              '一站式移动金融APP开发及全面监控；丰富可用组件，动态发布和故障热修复。',
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '分布式中间件' },
          content: {
            className: 'content3-content',
            children:
              '金融级联机交易处理中间件，大规模分布式计算机，数万笔/秒级并发能力，严格保证交易数据统一性。',
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '大数据' },
          content: {
            className: 'content3-content',
            children:
              '一站式、全周期大数据协同工作平台，PB级数据处理、毫秒级数据分析工具。',
          },
        },
      },
    ],
  },
};
export const Content4000DataSource = {
  wrapper: { className: 'home-page-wrapper content400-wrapper' },
  page: { className: 'home-page content400' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '一條龍完整服務 盡善盡美',
        className: 'title-h1 good-word-break',
      },
      // {
      //   name: 'content',
      //   className: 'title-content good-word-break',
      //   children: '專業分工 / 將行銷省時省力，共創最大效益',
      // },
      // {
      //   name: 'content',
      //   className: 'title-content good-word-break',
      //   children: '用心洞察 / 剖析探討消費者想法，觸及TA內心',
      // },
      // {
      //   name: 'content',
      //   className: 'title-content good-word-break',
      //   children: '換位思考 / 站在客戶端思量，為客戶解決問題',
      // }
    ],
  },
  mobileTitleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '一條龍完整服務 盡善盡美',
        className: 'title-h1 good-word-break',
      },
      {
        name: 'content',
        className: 'title-content good-word-break',
        children: <span>專業分工 /<br/>將行銷省時省力，共創最大效益</span>,
      },
      {
        name: 'content',
        className: 'title-content good-word-break',
        children: <span>用心洞察 /<br/>剖析探討消費者想法，觸及TA內心</span>,
      },
      {
        name: 'content',
        className: 'title-content good-word-break',
        children: <span>換位思考 /<br/>站在客戶端思量，為客戶解決問題</span>,
      }
    ],
  },
  block: {
    className: 'content400-block-wrapper',
    children: [
    ],
  },
};
export const Content4100DataSource = {
  wrapper: { className: 'home-page-wrapper content410-wrapper' },
  page: { className: 'home-page content410' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper align-left',
    children: [
      {
        name: 'title',
        children: '獨家優勢 任務必達 效益加值',
        className: 'title-h1 mobile-hidden',
      },
    ],
  },
  block: {
    className: 'content410-block-wrapper',
    children: [
    ],
  },
};
export const Content4200DataSource = {
  wrapper: { className: 'home-page-wrapper content420-wrapper' },
  page: { className: 'home-page content420' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper align-left',
    children: [
      {
        name: 'title',
        children: '以資料庫核心 整合數位資源',
        className: 'title-h1 good-word-break',
      },
      {
        name: 'content',
        className: 'content420-title-content title-content good-word-break',
        children: '以獨家分眾投放所累積的資料庫為核心，整合數位資源',
      },
      {
        name: 'content',
        className: 'content420-title-content title-content good-word-break',
        children: '提供全方位的策略方案，可大幅降低數位的行銷成本',
      },
    ],
  },
  block: {
    className: 'content420-block-wrapper',
    children: [
    ],
  },
};
export const Feature80DataSource = {
  wrapper: { className: 'home-page-wrapper feature8-wrapper' },
  page: { className: 'home-page feature8' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature8-title-wrapper',
    children: [
      { name: 'title', className: 'feature8-title-h1', children: '使用流程' },
      {
        name: 'content',
        className: 'feature8-title-content',
        children: '流程简单清晰，快速响应需求',
      },
    ],
  },
  childWrapper: {
    className: 'feature8-button-wrapper',
    children: [
      {
        name: 'button',
        className: 'feature8-button',
        children: { href: '#', children: '立即体验' },
      },
    ],
  },
  Carousel: {
    dots: true,
    arrows: true,
    className: 'feature8-carousel',
    wrapper: { className: 'feature8-block-wrapper' },
    children: {
      className: 'feature8-block',
      titleWrapper: {
        className: 'feature8-carousel-title-wrapper',
        title: { className: 'feature8-carousel-title' },
      },
      children: [
        {
          name: 'block0',
          className: 'feature8-block-row',
          gutter: 120,
          title: {
            className: 'feature8-carousel-title-block',
            children: '平台自主训练流程',
          },
          children: [
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '需求沟通',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: '沟通业务需求，对接人：诚凡、芸彩',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '需求沟通',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '沟通业务需求，对接人：诚凡、芸彩沟通业务需求，对接人：诚凡、芸彩',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '需求沟通',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '沟通业务需求，对接人：诚凡、芸彩沟通业务需求，对接人：诚凡、芸彩',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child3',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '需求沟通',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '沟通业务需求，对接人：诚凡、芸彩沟通业务需求，对接人：诚凡、芸彩',
                  },
                ],
              },
            },
          ],
        },
        {
          name: 'block1',
          className: 'feature8-block-row',
          gutter: 120,
          title: {
            children: '平台自主训练流程',
            className: 'feature8-carousel-title-block',
          },
          children: [
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '需求沟通',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: '沟通业务需求，对接人：诚凡、芸彩',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '需求沟通',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '沟通业务需求，对接人：诚凡、芸彩沟通业务需求，对接人：诚凡、芸彩',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '需求沟通',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '沟通业务需求，对接人：诚凡、芸彩沟通业务需求，对接人：诚凡、芸彩',
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child3',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '需求沟通',
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '沟通业务需求，对接人：诚凡、芸彩沟通业务需求，对接人：诚凡、芸彩',
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  },
};
export const Feature50DataSource = {
  wrapper: { className: 'home-page-wrapper content7-wrapper' },
  page: { className: 'home-page content7' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '蚂蚁金融云提供专业的服务',
        className: 'title-h1',
      },
      { name: 'content', children: '基于阿里云计算强大的基础资源' },
    ],
  },
  tabsWrapper: { className: 'content7-tabs-wrapper' },
  block: {
    children: [
      {
        name: 'block0',
        tag: {
          className: 'content7-tag',
          text: { children: 'PHONE', className: 'content7-tag-name' },
          icon: { children: 'mobile' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <>
                <span>
                  <h3>技术</h3>
                  <p>
                    丰富的技术组件，简单组装即可快速搭建金融级应用，丰富的技术组件，简单组装即可快速搭建金融级应用。
                  </p>
                  <br />
                  <h3>融合</h3>
                  <p>
                    解放业务及技术生产力，推动金融服务底层创新，推动金融服务底层创新。解放业务及技术生产力，推动金融服务底层创新。
                  </p>
                  <br />
                  <h3>
                    开放
                  </h3>符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。
                </span>
              </>
            ),
          },
          img: {
            className: 'content7-img',
            children:
              'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
            md: 10,
            xs: 24,
          },
        },
      },
      {
        name: 'block1',
        tag: {
          className: 'content7-tag',
          icon: { children: 'tablet' },
          text: { className: 'content7-tag-name', children: 'TABLET' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <>
                <span>
                  <h3>技术</h3>
                  <p>
                    丰富的技术组件，简单组装即可快速搭建金融级应用，丰富的技术组件，简单组装即可快速搭建金融级应用。
                  </p>
                  <br />
                  <h3>融合</h3>
                  <p>
                    解放业务及技术生产力，推动金融服务底层创新，推动金融服务底层创新。解放业务及技术生产力，推动金融服务底层创新。
                  </p>
                  <br />
                  <h3>
                    开放
                  </h3>符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。
                </span>
              </>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children:
              'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
          },
        },
      },
      {
        name: 'block2',
        tag: {
          className: 'content7-tag',
          text: { children: 'DESKTOP', className: 'content7-tag-name' },
          icon: { children: 'laptop' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <>
                <span>
                  <h3>技术</h3>
                  <p>
                    丰富的技术组件，简单组装即可快速搭建金融级应用，丰富的技术组件，简单组装即可快速搭建金融级应用。
                  </p>
                  <br />
                  <h3>融合</h3>
                  <p>
                    解放业务及技术生产力，推动金融服务底层创新，推动金融服务底层创新。解放业务及技术生产力，推动金融服务底层创新。
                  </p>
                  <br />
                  <h3>
                    开放
                  </h3>符合金融及要求的安全可靠、高可用、高性能的服务能力，符合金融及要求的安全可靠、高可用、高性能的服务能力。
                </span>
              </>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children:
              'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
          },
        },
      },
    ],
  },
};
export const Contact00DataSource = {
  wrapper: { className: 'home-page-wrapper content10-wrapper' },
  Content: {
    className: 'icon-wrapper',
    children: {
      icon: {
        className: 'icon',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/zIUVomgdcKEKcnnQdOzw.svg',
        name: '主要图标',
      },
      iconShadow: {
        className: 'icon-shadow',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/WIePwurYppfVvDNASZRN.svg',
        name: '图标影阴',
      },
      url: { children: 'https://gaode.com/place/B0FFH3KPBX', name: '跳转地址' },
      title: { children: '大会地址', name: '弹框标题' },
      content: {
        children: '蚂蚁 Z 空间  浙江省杭州市西湖区西溪路556号',
        name: '弹框内容',
      },
    },
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <>
        <span>
          Copyright© 2019 by Beeplus all Rights Reserved.
        </span>
      </>
    ),
  },
};
