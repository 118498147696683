import React from 'react';
import Media from 'react-media'

class ClientMedia extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isClient: false,
      defaultQuery: {
        large: { minWidth: 1024 },
      },
    }
  }

  componentDidMount() {
    this.setState({ isClient: true })
  }

  render() {
    const { children, queries } = this.props
    const { isClient, defaultQuery } = this.state

    if (isClient) {
      return <Media queries={queries || defaultQuery}>
        {children}
      </Media>
    } else return <div></div>
  }
}

export default ClientMedia
